<template>
  <div>
    <Landing
      :role="role"
      :tabs="tabs"
      :mainRouter="`EducatorHome`"
      :subTitle="subTitle"
      :mainTabs="mainTabs"
      :stories="stories"
      :storiesTitle="storiesTitle"
    ></Landing>
    <div class="container plan-container mb-4">
      <Plan></Plan>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Landing from "@/views/landingPage/components/Index.vue";
import Plan from "@/views/plans/Index.vue";

export default {
  metaInfo() {
    return {
      title: `For ${this.role} - ` + this.CompanyName
    };
  },

  components: { Landing,Plan },

  mixins: [],

  props: [],
  data() {
    return {
      //Role
      role: "Educators",
      //SubTitle
      subTitle: {
        title: "The everything site for test prep instruction",
        smallTitle:
          "AI-powered insights and data-driven features will enhance your expertise and lead to better student results."
      },
      // Tabs
      tabs: [
        {
          icon: "fas fa-user-plus",
          title: "Smart Performance Reports",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Connect+Students.png"
        },
        {
          icon: "fas fa-school",
          title: "Dynamic Progress Tracker",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Set+Up+Classes.png"
        },
        {
          icon: "fas fa-grin-stars",
          title: "Adaptive Assignment Creator",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Review+Overall+Class+Results.png"
        },
        {
          icon: "fas fa-house-user",
          title: "Intelligent Intervention Alerts",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Assign+Homework+.png"
        },
        {
          icon: "fas fa-chart-bar",
          title: "Engagement Analytics",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Assign+Homework.png"
        },
        {
          icon: "fas fa-mail-bulk",
          title: "Predictive Score Insights",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Assign+Drills.png"
        },
        {
          icon: "fas fa-spell-check",
          title: "Smart Curriculum Builder",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Create+Custom+Questions+and+Tests.png"
        }
        // {
        //   icon: "fas fa-seedling",
        //   title: "Oversee",
        //   img:
        //     "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/Ovcersee.png"
        // }
      ],
      // mainTabs
      mainTabs: [
        {
          title: "Connect Students",
          text:
            "Get started by creating a game plan for your test prep by using our AI-powered study plan generator",
          img: ""
        },
        {
          title: "Track Progress",
          text:
            "Monitor student progress in real-time, assign drills and practice tests, and analyze results",
          img: ""
        },
        {
          title: "Maximize Results",
          text: "Streamline student management with automated tracking and insights, crushing student goals",
          img: ""
        }
      ],
      // Stories
      stories: [
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/users/Kelly.png",
          cover: "",
          name: "Kelly",
          title: "High School Counselor",
          words: "Thanks to ACT Mocks and SAT Mocks, many of my students have seen incredible improvements in their scores, leading to more college acceptances and scholarships than ever before. X Mocks has been an essential part of our college guidance programming!"
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/users/Sree.png",
          cover: "",
          name: "Sree",
          title: "Tutoring Company Owner",
          words: "We white-labeled X Mocks and use the platform for our test prep tutoring services. The customizable practice tests and detailed progress tracking have made our tutoring sessions more effective. X Mocks has elevated our services to the next level."
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/users/Lisa.png",
          cover: "",
          name: "Lisa",
          title: "Tutor",
          words: "I use ACT Mocks with all my students, creating personalized classes and keeping track of their progress. The practice exams mimic the real thing, and the detailed analytics help me tailor my teaching to each student's needs. 10/10!"
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/users/Mark.png",
          cover: "",
          name: "Mark",
          title: "Home-Schooling Parent",
          words: "We homeschool our son, and SAT Mocks helped him improve 380 points in just a couple of months. The interactive practice tests and clear explanations were exactly what he needed to boost his confidence and performance. He is thrilled about his 1560!"
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/users/Etta.png",
          cover: "",
          name: "Etta",
          title: "AP US History Teacher",
          words: "I have loved beta testing AP Mocks! My students use the platform’s comprehensive study materials and practice questions that closely mirror the actual exams, giving them the practice they need to score 4s and 5s on the test."
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage/users/Charles.png",
          cover: "",
          name: "Charles",
          title: "College Admissions Consultant",
          words: "I tell all my clients to start ACT or SAT prep early, and I always point them to X Mocks. This platform has helped students I work with achieve impressive scores, opening doors to top-tier colleges and universities."
        }
      ],
      storiesTitle: {
        title: "Educator Success Stories",
        smallTitle:
          "From high school counselors to non-profit organizers to test prep tutors, <br />these educators used XMocks to help their students crush their goals!",
        count: "3,000"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.plan-container {
  max-width: calc(1280px + 2 * var(--layout-padding));
  margin: 0 auto;
  padding: 0 var(--layout-padding);
  width: auto;
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
}
</style>